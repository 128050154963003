import { createTheme } from '@mui/material/styles'

const styleOverrides = `
  html, body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden !important;
  }

  main {
    background-color: white;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
  
  code {
    background: #fff2f3;
    border: 1px solid #ffa1a7;
    border-radius: 10px;
    padding: 16px;
    display: block;
    overflow-wrap: anywhere;
    font-family: monospace !important;
    font-size: 12px;
    color: #ff3845;
  }

  @font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("/Futura Condensed Extra Bold.otf");
  }

  :root {
    --swiper-theme-color: white;
  }
`

const navy = '#013049'
export const yellow = '#FABD47'

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides,
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: navy,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: navy,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: 'secondary',
        underline: 'always',
        fontWeight: 600,
      },
    },
  },
  palette: {
    text: {
      primary: navy,
      secondary: yellow,
    },
    primary: {
      main: yellow,
      dark: '#dfa83d',
      light: '#f7f7f7',
      contrastText: navy,
    },
    secondary: {
      main: navy,
      dark: '#001a28',
      contrastText: yellow,
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: ['Neue Haas Grotesk Display Pro', 'sans-serif'].join(','),
    h2: {
      fontFamily: 'Futura Condensed Extra Bold',
      fontSize: '1.6rem',
      fontWeight: 500,
      lineHeight: '24px',
      letterSpacing: 'px',
      textAlign: 'left',
      textTransform: 'uppercase',
    },
    body2: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      textAlign: 'left',
      color: '#666666',
    },
    button: {
      fontFamily: 'Futura Condensed Extra Bold',
    },
  },
})
