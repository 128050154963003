// import axios from 'axios'
import { useMediaQuery, useTheme } from '@mui/material'
import { useRouter } from 'next/router'
import { createContext, useEffect, useState } from 'react'
import { Brand } from 'strikemap-common/lib/types'

type MarkerClick = { id: number | null; idx: number }

interface ContextProps {
  menuOpen: boolean
  setMenuOpen: (open: boolean) => void
  activeSearch?: string
  setActiveSearch: (newSearch: string) => void
  markerClicked?: MarkerClick
  updateMarkerClicked?: (id: number) => void
  embed?: boolean
  setEmbed: (embed: boolean) => void
  brand?: Brand
  setBrand: (brand: Brand | undefined) => void
  showEndedStrikes?: boolean
  setShowEndedStrikes: (show: boolean) => void
  desktop?: boolean
}

type ProviderProps = {
  children?: React.ReactNode
}

const defaultProps: ContextProps = {
  menuOpen: false,
  setMenuOpen: () => null,
  activeSearch: '',
  setActiveSearch: () => null,
  updateMarkerClicked: () => null,
  markerClicked: { id: null, idx: 0 },
  embed: false,
  setEmbed: () => null,
  setBrand: () => null,
  setShowEndedStrikes: () => null,
}

export const StrikeContext = createContext<ContextProps>(defaultProps)

export const StrikeProvider: React.FC<ProviderProps> = ({ children }) => {
  const router = useRouter()
  const theme = useTheme()
  const [menuOpen, setMenuOpen] = useState(false)
  const [brand, setBrand] = useState<Brand>()
  const [activeSearch, setActiveSearch] = useState<string>()
  const [markerClicked, setMarkerClicked] = useState<
    ContextProps['markerClicked']
  >(defaultProps.markerClicked)
  const [embed, setEmbed] = useState(false)
  const [showEndedStrikes, setShowEndedStrikes] = useState(false)

  useEffect(() => {
    setMenuOpen(false)
    if (!router.asPath.includes('/strikes')) setActiveSearch('')
  }, [router.asPath])

  const desktop = useMediaQuery(theme.breakpoints.up('md'))

  const value = {
    menuOpen,
    setMenuOpen,
    activeSearch,
    setActiveSearch,
    markerClicked,
    updateMarkerClicked,
    embed,
    setEmbed,
    brand,
    setBrand,
    showEndedStrikes,
    setShowEndedStrikes,
    desktop,
  }

  return (
    <StrikeContext.Provider value={value}>{children}</StrikeContext.Provider>
  )

  function updateMarkerClicked(id: number) {
    if (markerClicked) setMarkerClicked({ id, idx: markerClicked.idx + 1 })
  }
}
