import { CacheProvider } from '@emotion/react'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { StyledEngineProvider, Theme } from '@mui/material/styles'
import '@mui/styles'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import calendar from 'dayjs/plugin/calendar'
import relativeTime from 'dayjs/plugin/relativeTime'
import { CampaignProvider } from 'lib/browser/CampaignProvider'
import createEmotionCache from 'lib/browser/emotionCache'
import * as gtag from 'lib/browser/gtag'
import { StrikeProvider } from 'lib/browser/StrikeProvider'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { ReactElement, ReactNode, useEffect } from 'react'
import { MapProvider } from 'react-map-gl'
import { StrikePublic } from 'strikemap-common/lib/types'
import { theme } from 'styles/styled'

dayjs.extend(relativeTime)
dayjs.extend(calendar)
const clientSideEmotionCache = createEmotionCache()

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

type NextPageWithLayout = NextPage & {
  // eslint-disable-next-line unused-imports/no-unused-vars
  getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & { strikes: StrikePublic[] } & {
  Component: NextPageWithLayout
  pageProps: AppProps['pageProps']
}

export default function App({
  Component,
  pageProps: { ...pageProps },
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page)
  const router = useRouter()

  useEffect(() => {
    if (!(process?.env?.NEXT_PUBLIC_NODE_ENV === 'development')) {
      const handleRouteChange = (url: string) => {
        gtag.pageview(url)
      }
      router.events.on('routeChangeComplete', handleRouteChange)
      router.events.on('hashChangeComplete', handleRouteChange)
      return () => {
        router.events.off('routeChangeComplete', handleRouteChange)
        router.events.off('hashChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])

  return (
    <>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Head>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width, user-scalable=no"
        />
        <meta property="og:title" content={pageProps.title} />
        <meta property="og:description" content={pageProps.description} />
        <meta property="og:image" content={pageProps.shareImage} />
        <meta property="og:url" content="https://strikemap.org" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@strike_map" />
        <meta name="twitter:title" content={pageProps.title} />
        <meta name="twitter:description" content={pageProps.description} />
        <meta name="twitter:image" content={pageProps.shareImage} />
        <meta property="twitter:url" content="https://strikemap.org" />
        <meta property="twitter:domain" content="strikemap.org" />
      </Head>
      {!(process?.env?.NEXT_PUBLIC_NODE_ENV === 'development') && (
        <>
          <Script
            strategy="afterInteractive"
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
          />
          <Script
            id="gtag-init"
            strategy="afterInteractive"
            referrerPolicy="no-referrer-when-downgrade"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_TRACKING_ID}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      <StyledEngineProvider injectFirst>
        <CacheProvider value={clientSideEmotionCache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StrikeProvider>
                <MapProvider>
                  <CampaignProvider>
                    {getLayout(<Component {...pageProps} />)}
                  </CampaignProvider>
                </MapProvider>
              </StrikeProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </CacheProvider>
      </StyledEngineProvider>
    </>
  )
}
