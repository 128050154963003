import React, { createContext, useContext } from 'react'
import { Campaign } from 'strikemap-common/lib/types'
import useSWR from 'swr'
import { fetchJson } from './fetching'

// Define the type for your campaign context
type CampaignContextType = {
  activeCampaigns?: Campaign[]
  featuredCampaign?: Campaign | null
}

// Create the campaign context
export const CampaignContext = createContext<CampaignContextType>({
  activeCampaigns: [],
  featuredCampaign: null,
})

// Create the campaign provider
export const CampaignProvider: React.FC = ({ children }) => {
  const { data } = useSWR<{
    campaigns?: Campaign[]
    featuredCampaign?: Campaign
  }>('/api/campaigns', fetchJson)

  return (
    <CampaignContext.Provider
      value={{
        activeCampaigns: data?.campaigns,
        featuredCampaign: data?.featuredCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export const useCampaigns = (): CampaignContextType => {
  const context = useContext(CampaignContext)
  if (!context) {
    throw new Error('useCampaignContext must be used within a CampaignProvider')
  }
  return context
}
