import axios, { AxiosRequestConfig } from 'axios'

export function fetchJson(url: string, config?: AxiosRequestConfig) {
  return axios({ url, method: 'get', ...config }).then((res) => res.data)
}

export function swrPost({ url, data }: { url: string; data: any }) {
  return axios.post(url, data).then((res) => res.data)
}

export function postJson({ url, data }: { url: string; data: any }) {
  return axios.post(url, data)
}
